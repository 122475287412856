// Scavenge.tsx 
import * as React from 'react'; 
import Scavenge from '../scavenge/Scavenge';

const ScavengePage: React.FC = () => {   
  return ( 
    <Scavenge />
  );
} 

export default ScavengePage;